import { init, track, setUserId, add } from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { useRouteLoaderData } from '@remix-run/react'
import { useEffect, createContext } from 'react'
import { type loader } from '#app/root'

interface AmplitudeContextType {
	trackAmplitudeEvent: (eventName: string, eventProperties?: any) => void
	setAmplitudeUserId: (userId: string | null) => void
}

export const AmplitudeContext = createContext<AmplitudeContextType>({
	trackAmplitudeEvent: () => {},
	setAmplitudeUserId: () => {},
})

interface AmplitudeContextProviderProps {
	children: React.ReactNode
}
const AmplitudeContextProvider = ({
	children,
}: AmplitudeContextProviderProps) => {
	const rootData = useRouteLoaderData<typeof loader>('root')

	const env = rootData?.ENV

	useEffect(() => {
		if (env?.AMPLITUDE_API_KEY) {
			init(env.AMPLITUDE_API_KEY, undefined, {
				serverZone: 'EU',
				autocapture: {
					elementInteractions: false,
				},
				defaultTracking: {
					sessions: true,
				},
			})

			const sessionReplay = sessionReplayPlugin({ sampleRate: 0.01 })

			add(sessionReplay)
		} else {
			console.log('No amplitude key found')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const trackAmplitudeEvent = (eventName: string, eventProperties?: any) => {
		track(eventName, eventProperties ?? {})
	}

	const setAmplitudeUserId = (userId: string | null) => {
		setUserId(userId ?? undefined)
	}

	const value = { trackAmplitudeEvent, setAmplitudeUserId }
	return (
		<AmplitudeContext.Provider value={value}>
			{children}
		</AmplitudeContext.Provider>
	)
}

export default AmplitudeContextProvider
